import React from 'react';
import TaraChat from './TaraChat';
import TaraInput from '../taraUI/TaraInput';
import { useParams } from 'react-router-dom';

type Props = {
  onCurrentChatUpdate: (chat: any) => void;
  cancelAPICall: () => void;
  loading: 'pending' | 'success' | 'error' | 'null';
};

const CurrentChat = ({
  onCurrentChatUpdate,
  cancelAPICall,
  loading,
}: Props) => {
  const { conversationId } = useParams();
  return (
    <div style={{ paddingTop: '2rem' }} className='tara tara-active'>
      <TaraChat />
      <TaraInput
        updateChat={onCurrentChatUpdate}
        cancelAPI={cancelAPICall}
        loading={loading}
      />
    </div>
  );
};

export default CurrentChat;
