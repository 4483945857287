import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import '../../styles/pages/tara/tara.scss';
import TaraHeading from './taraUI/TaraHeading';
import TaraSearchSuggestions from './taraUI/TaraSearchSuggestions';
import TaraInput from './taraUI/TaraInput';
import TaraChat from './chat/TaraChat';
import { Chat, CurrentChatUpdate } from '../../interfaces/taraInterfaces';
import generateRandomString from '../../lib/helper-functions/generateRandomString';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  setAllChatsHistory,
  setCurrentChatID,
  setHistoryLoading,
  setIsBotTyping,
} from '../../store/reducers/chatSlice';
import { allRoutes, HOME_ROUTE } from '../../lib/constants/route';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import TaraSearch from './search/TaraSearch';
import getCurrentRoute from '../../lib/helper-functions/getCurrentRoute';
import tara_chat_api_call from '../../lib/api/tara/tara-chat-api-call';
import tara_chat_history_api_call from '../../lib/api/tara/tara_chat_history_api_call';
import TaraNewChat from './chat/TaraNewChat';
import CurrentChat from './chat/CurrentChat';

const Tara = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentRoute = getCurrentRoute(location);
  const dispatch = useDispatch();

  const currentChatID = useSelector(
    (state: RootState) => state.chat.currentChatID
  );
  const allChatsHistory = useSelector(
    (state: RootState) => state.chat.allChatsHistory
  );
  const currentChat =
    allChatsHistory.find((chat) => chat.chatId === currentChatID) || null;

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');

  // Ref to hold current API call's AbortController.
  const abortControllerRef = useRef<AbortController | null>(null);

  const onCurrentChatUpdate = ({
    newChatId,
    newChat,
    role,
    messageType,
  }: CurrentChatUpdate) => {
    if (!currentChat) {
      const tempChatId = generateRandomString(10);
      const firstChat: Chat = {
        chatId: tempChatId,
        chatStartedDate: new Date().toISOString(),
        chatLastUpdatedDate: new Date().toISOString(),
        chatMessages: [
          {
            id: generateRandomString(10),
            message: newChat,
            messageType: 'text',
            role: role,
          },
        ],
      };
      dispatch(setAllChatsHistory([...allChatsHistory, firstChat]));
      dispatch(setCurrentChatID(firstChat.chatId));
    } else {
      if (role === 'ai' && currentChat.chatMessages.length === 1 && newChatId) {
        const updatedChat: Chat = {
          ...currentChat,
          chatId: newChatId,
          chatLastUpdatedDate: new Date().toISOString(),
          chatMessages: [
            ...currentChat.chatMessages,
            {
              id: newChatId,
              message: newChat,
              messageType: messageType,
              role: role,
            },
          ],
        };
        const updatedHistory = allChatsHistory.map((chat) =>
          chat.chatId === currentChat.chatId ? updatedChat : chat
        );
        dispatch(setAllChatsHistory(updatedHistory));
        dispatch(setCurrentChatID(newChatId));
      } else {
        const updatedChat: Chat = {
          ...currentChat,
          chatLastUpdatedDate: new Date().toISOString(),
          chatMessages: [
            ...currentChat.chatMessages,
            {
              id: newChatId || generateRandomString(10),
              message: newChat,
              messageType: messageType,
              role: role,
            },
          ],
        };
        const updatedHistory = allChatsHistory.map((chat) =>
          chat.chatId === currentChat.chatId ? updatedChat : chat
        );
        dispatch(setAllChatsHistory(updatedHistory));
      }
    }
  };

  useEffect(() => {
    if (
      !allChatsHistory ||
      allChatsHistory.length === 0 ||
      (currentRoute === 'tara' && currentChatID === null)
    ) {
      dispatch(setHistoryLoading('pending'));
      tara_chat_history_api_call(1, 5)
        .then((res) => {
          if (
            res &&
            res.data &&
            Array.isArray(res.data) &&
            res.data.length > 0
          ) {
            const chats: Chat[] = res.data.map((item: any) => ({
              chatId: item.conversation_id,
              chatStartedDate: item.chatStartedDate,
              chatLastUpdatedDate: item.chatLastUpdatedDate,
              chatMessages: item.conversations.map((conv: any) => ({
                id: conv.chatId,
                message: conv.message,
                messageType: 'text',
                role: conv.role,
              })),
            }));
            dispatch(setAllChatsHistory(chats));
          } else if (
            res &&
            res.data &&
            Array.isArray(res.data) &&
            res.data.length === 0
          ) {
            dispatch(setAllChatsHistory([]));
          }
          dispatch(setHistoryLoading('success'));
          setLoading('success');
        })
        .catch((error) => {
          console.error('Error fetching tara chat history:', error);
          dispatch(setHistoryLoading('error'));
          setLoading('error');
        });
    }
  }, [currentRoute, currentChatID]);

  const taraAPICall = async (currentMessages: string) => {
    try {
      // Create a new AbortController and store it.
      const controller = new AbortController();
      abortControllerRef.current = controller;
      setLoading('pending');
      const res = await tara_chat_api_call(currentMessages, controller.signal);
      // Check if the request was aborted.
      if (res && res.aborted) {
        console.log('API call aborted; not updating chat.');
        dispatch(setIsBotTyping(false));
        setLoading('null');
        return;
      }
      if (
        res?.conversation_id &&
        res?.message &&
        typeof res?.message === 'string' &&
        typeof res?.conversation_id === 'string'
      ) {
        // Successful response: update the chat with the AI's message.
        onCurrentChatUpdate({
          newChatId: res.conversation_id,
          newChat: res.message,
          role: 'ai',
          messageType: 'text',
        });
        dispatch(setIsBotTyping(false));
        setLoading('success');
      } else {
        // In all failure cases, update the chat with an error message.
        setLoading('null');
        dispatch(setIsBotTyping(false));
        onCurrentChatUpdate({
          newChatId: res?.conversation_id || '',
          newChat: 'Sorry, something went wrong. Please try again.',
          role: 'ai',
          messageType: 'text',
        });
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('API call aborted.');
        dispatch(setIsBotTyping(false));
        setLoading('null');
        return;
      }
      console.error('Error fetching tara chat:', error);
      dispatch(setIsBotTyping(false));
      onCurrentChatUpdate({
        newChatId: '',
        newChat: 'Sorry, something went wrong. Please try again.',
        role: 'ai',
        messageType: 'text',
      });
      setLoading('error');
    }
  };

  // Cancel API call on user request.
  const cancelAPICall = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      dispatch(setIsBotTyping(false));
      setLoading('null');
      onCurrentChatUpdate({
        newChatId: '',
        newChat: 'Response stopped by user!',
        role: 'ai',
        messageType: 'text',
      });
    }
  };

  // Trigger the bot response when the last message is from the user.
  useEffect(() => {
    if (currentChat && currentChat.chatMessages.length > 0) {
      const lastMessage =
        currentChat.chatMessages[currentChat.chatMessages.length - 1];
      if (lastMessage.role === 'user') {
        dispatch(setIsBotTyping(true));
        taraAPICall(lastMessage.message);
      }
    }
  }, [currentChat, dispatch]);

  return (
    <PageWrapperMain>
      <SubPageContentHolder>
        <SubPageInnerContentHolder
          title={t('Ask Tara')}
          subTitle={t('Your AI Data & E-commerce Agent')}
          backgroundColor='var(--sidebar-background-color)'
          padding='2rem 0rem'
          backBtnLink={currentRoute === 'search' ? HOME_ROUTE : undefined}
        >
          <Routes>
            <Route
              path='/'
              element={
                !currentChat ? (
                  <TaraNewChat
                    onCurrentChatUpdate={onCurrentChatUpdate}
                    cancelAPICall={cancelAPICall}
                    loading={loading}
                  />
                ) : (
                  <Navigate to={`/tara/chat/${currentChat?.chatId}`} />
                )
              }
            />
            <Route
              path='/chat/:conversationId'
              element={
                <CurrentChat
                  onCurrentChatUpdate={onCurrentChatUpdate}
                  cancelAPICall={cancelAPICall}
                  loading={loading}
                />
              }
            />
            {allRoutes['/tara/search'] && (
              <Route path='search/*' element={<TaraSearch />} />
            )}
          </Routes>
        </SubPageInnerContentHolder>
      </SubPageContentHolder>
    </PageWrapperMain>
  );
};

export default Tara;
