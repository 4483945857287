import { useTranslation } from 'react-i18next';
import StatusCard from '../../../../ui/cards/StatusCard';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';
import mcpImg from '../../../../assets/mcp.png';
import { useEffect, useState } from 'react';
import get_all_available_mcp_api_call from '../../../../lib/api/mcp/get-all-available-mcp';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import get_all_connected_mcp_api_call from '../../../../lib/api/mcp/get-all-connected-mcp';
import Popup from '../../../../ui/popup/Popup';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import AINormalInput from '../../../../ui/inputs/AINormalInput';
import convertSnakeCaseToTitleCase from '../../../../lib/helper-functions/convertSnakeCaseToTitleCase';
import connect_mcp_api_call from '../../../../lib/api/mcp/connect-mcp';
import NoData from '../../../../ui/noData/NoData';
import update_mcp_api_call from '../../../../lib/api/mcp/update-mcp';
import delete_mcp_api_call from '../../../../lib/api/mcp/delete-mcp';
import { submitLogToBugsnag } from '../../../../lib/api/log';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import removeHttpPrefix from '../../../../lib/helper-functions/removeHttpPrefix';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';

const MCP = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mcpList, setMcpList] = useState<any[]>([]);

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editingMCP, setEditingMCP] = useState<any>();

  const [
    isActivationToggleConfirmationVisible,
    setIsActivationToggleConfirmationVisible,
  ] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleEditPopupModal = () => {
    if (!isEditPopupOpen === false) {
      setEditingMCP(undefined);
    }
    setIsEditPopupOpen(!isEditPopupOpen);
    setIsActivationToggleConfirmationVisible(false);
    setIsConnecting(false);
    setIsUpdating(false);
    setIsDeleting(false);
  };

  useEffect(() => {
    getMCPs();
  }, []);

  const getMCPs = async () => {
    function processData(allMCPs: [], connectedMCPs: []) {
      const connectionMap = new Map();
      connectedMCPs.forEach((item: any) => {
        connectionMap.set(item.tara_mcp_id, item);
      });

      const processedData = allMCPs.map((item: any) => {
        return {
          id: item?.id,
          mcp_name: item?.mcp_name,
          credentials_fields: item?.credentials_fields,
          logo: item?.logo,
          connection_id: connectionMap.get(item.id)?.id,
          credentials: connectionMap.get(item.id)?.credentials,
          is_active: connectionMap.get(item.id)?.is_active,
          is_AdUp: item?.mcp_name === 'AdUp AI Tracking' ? true : false,
        };
      });

      //? Reorder the array to have AdUp MCP as the first element
      const adupMCP = processedData.filter((item) => item?.is_AdUp);
      const otherMCPs = processedData.filter((item) => !item?.is_AdUp);

      return [...adupMCP, ...otherMCPs];
    }

    try {
      setIsLoading(true);
      const allMCPs = get_all_available_mcp_api_call();
      allMCPs.then((allMCPs: []) => {
        const connectedMCPs = get_all_connected_mcp_api_call();
        connectedMCPs.then((connectedMCPs: []) => {
          const data = processData(allMCPs, connectedMCPs);
          setMcpList(data);
          setIsLoading(false);
        });
      });
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
      submitLogToBugsnag('error', `Error in fetching MCP data: ${error}`);
    }
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const mode = editingMCP?.is_active !== undefined ? 'update' : 'add';
    if (mode === 'add') {
      try {
        setIsConnecting(true);
        const response = await connect_mcp_api_call(
          editingMCP.id,
          editingMCP.credentials
        );
        if (response) {
          setIsConnecting(false);
          getMCPs();
          toggleEditPopupModal();
        } else {
          setIsConnecting(false);
        }
      } catch (error) {
        setIsConnecting(false);
        console.error('Error connecting MCP:', error);
        submitLogToBugsnag('error', `Error in connecting MCP: ${error}`);
      }
    } else {
      try {
        setIsUpdating(true);
        const response = await update_mcp_api_call(
          editingMCP.connection_id,
          editingMCP.credentials,
          editingMCP.is_active
        );
        if (response) {
          setIsUpdating(false);
          getMCPs();
          toggleEditPopupModal();
        } else {
          setIsUpdating(false);
        }
      } catch (error) {
        setIsUpdating(false);
        console.error('Error updating MCP:', error);
        submitLogToBugsnag('error', `Error in updating MCP: ${error}`);
      }
    }
  };

  const deleteHandler = async (e: any) => {
    e.preventDefault();
    try {
      setIsDeleting(true);
      const response = await delete_mcp_api_call(editingMCP.connection_id);
      if (response) {
        setIsDeleting(false);
        getMCPs();
        toggleEditPopupModal();
      } else {
        setIsDeleting(false);
      }
    } catch (error) {
      setIsDeleting(false);
      console.error('Error deleting MCP:', error);
      submitLogToBugsnag('error', `Error in deleting MCP: ${error}`);
    }
  };

  return (
    <>
      <div className='trackingIntegration'>
        <div className='trackingIntegration-wrapper'>
          <h1 className='trackingIntegration-title'>{t('MCP-Title')}</h1>
          <span className='trackingIntegration-subtitle'>
            {t('MCP-SubTitle')}
          </span>
          <div className='trackingIntegration-status'>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {mcpList?.length > 0 ? (
                  mcpList.map((mcp) => (
                    <StatusCard
                      key={mcp.id}
                      title={''}
                      info={makeFirstLetterCapital(mcp.mcp_name)}
                      active={mcp.is_active?.toString() ?? 'null'}
                      description={` : ${
                        mcp?.is_active === true
                          ? t('EditMCP')
                          : mcp?.is_active === false
                          ? t('ActivateMCP')
                          : t('AddMCP')
                      }`}
                      image={isNotEmptyString(mcp?.logo) ? mcp?.logo : mcpImg}
                      onClick={() => {
                        setEditingMCP(mcp);
                        toggleEditPopupModal();
                      }}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <NoData title={t('NoMCP')} subTitle={t('ContactSupport')} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Popup
        styles={{ backgroundColor: '#fff', width: '80%' }}
        setTrigger={toggleEditPopupModal}
        trigger={isEditPopupOpen}
      >
        <div className='customEvent__editPopup'>
          <form
            id='customCssTracking-form-update'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            <h1 className='customEvent-title'>
              {t('ManageMCP', {
                mcpName: makeFirstLetterCapital(editingMCP?.mcp_name),
              })}
            </h1>
            <div className='normalForm__top'>
              <div className='normalForm__container1'>
                {editingMCP?.credentials_fields?.map(
                  (field: string, index: number) => (
                    <div className='normalForm__container1--left'>
                      <AINormalInput
                        key={index}
                        changeListeners={[
                          (event) => {
                            setEditingMCP({
                              ...editingMCP,
                              credentials: {
                                ...editingMCP?.credentials,
                                [field]:
                                  field === 'MYSHOPIFY_DOMAIN'
                                    ? removeHttpPrefix(event.target.value)
                                    : event.target.value,
                              },
                            });
                          },
                        ]}
                        id={`${field}-input`}
                        value={editingMCP?.credentials?.[field] || ''}
                        placeholder={convertSnakeCaseToTitleCase(field)}
                        required={true}
                        innerPlaceholder={
                          t(`EnterYour`) +
                          ' ' +
                          convertSnakeCaseToTitleCase(field) +
                          (field === 'MYSHOPIFY_DOMAIN'
                            ? ' ("example.myshopify.com")'
                            : '')
                        }
                      />
                    </div>
                  )
                )}
              </div>

              {editingMCP?.is_active !== undefined && (
                <div style={{ paddingTop: '1rem' }}>
                  <ToggleSwitch
                    isOn={editingMCP?.is_active}
                    defaultChecked={editingMCP?.is_active}
                    title={t('Status')}
                    titleAlignment={'right'}
                    onClick={() => {
                      setIsActivationToggleConfirmationVisible(true);
                      setEditingMCP({
                        ...editingMCP,
                        is_active: !editingMCP?.is_active,
                      });
                    }}
                    disabled={false}
                    id='notification-settings-is-custom-message'
                  />
                </div>
              )}
              <p
                style={{
                  paddingTop: '0.5rem',
                  fontSize: '1rem',
                  fontStyle: 'italic',
                  color: '#7a7a7a',
                }}
              >
                {isActivationToggleConfirmationVisible
                  ? ' * ' + t('MCP-ActivationToggleConfirmation')
                  : ''}
              </p>
              <div
                className='normalForm__bottom flex-start'
                style={{
                  gap: '1rem',
                  paddingTop: !isActivationToggleConfirmationVisible
                    ? '1.6rem'
                    : '',
                }}
              >
                {editingMCP?.is_active !== undefined ? (
                  <>
                    {!editingMCP?.is_AdUp && (
                      <MainSquareButton
                        value={
                          isDeleting ? `${t('Deleting')}...` : t('DeleteMCP')
                        }
                        disabled={isConnecting || isUpdating || isDeleting}
                        type='button'
                        buttonType='danger'
                        form='customCssTracking-form-update'
                        onClick={deleteHandler}
                      />
                    )}
                    <MainSquareButton
                      value={
                        isUpdating ? `${t('Updating')}...` : t('UpdateMCP')
                      }
                      disabled={isConnecting || isUpdating || isDeleting}
                      type='submit'
                      buttonType='light'
                      form='customCssTracking-form-update'
                    />
                  </>
                ) : (
                  <MainSquareButton
                    value={
                      isConnecting ? `${t('Connecting')}...` : t('ConnectMCP')
                    }
                    disabled={isConnecting || isUpdating || isDeleting}
                    type='submit'
                    buttonType='light'
                    form='customCssTracking-form-update'
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </Popup>
    </>
  );
};

export default MCP;
