import { t } from 'i18next';
import '../../styles/cards/statusCard.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';
interface Props {
  title: string;
  info: string;
  description: string;
  active?: string;
  color?: string;
  icon?: any;
  image?: any;
  onClick?: () => void;
}

const StatusCard = ({
  title,
  info,
  description,
  active,
  color,
  icon,
  image,
  onClick,
}: Props) => {
  return (
    <div
      className='statusCard'
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      {icon && (
        <div className='statusCard__icon'>
          <CustomSVGs svg={icon} />
        </div>
      )}
      <div className='statusCard__content-holder'>
        {image && (
          <div className='statusCard__image'>
            <img src={image} alt='status' />
          </div>
        )}
        <div className='statusCard__content'>
          <span>{title}</span>
          <h2
            style={{
              color:
                color === 'green'
                  ? '#28a745'
                  : color === 'red'
                  ? '#FF4433'
                  : 'var(--main-text-color)',
            }}
          >
            {info}
          </h2>

          <div className='statusCard__inner-content-holder'>
            {active !== undefined && (
              <p
                style={{
                  fontWeight: '600',
                  color:
                    active === 'true'
                      ? '#28a745'
                      : active === 'false'
                      ? '#f79640'
                      : '#FF4433',
                }}
              >
                {active === 'true'
                  ? t('Connected')
                  : active === 'false'
                  ? t('Deactivated')
                  : t('NotConnected')}
              </p>
            )}
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusCard;
