const version = 'v1';

let basePath = ''; // base URL of the APIs
let trackerBasePath = ''; // base URL of tracker APIs
let aiCheckoutTrackingScriptPath = '';
let env: 'DEV' | 'STAGING' | 'LIVE';
let shopifyClientId = '';
export const BUGSNAG_API_KEY = '0c051d898b4c705d9f58d66045fc561e';
export const GOOGLE_OAUTH_CLIENT_ID =
  '326264066299-2kr1t9sv8ijvflqbrarhkhg2tmac8d0f.apps.googleusercontent.com';
export const shopifyAdminURL = 'https://admin.shopify.com';
if (
  window.location.href.split('/')[2].includes('localhost') ||
  process.env.NODE_ENV === 'development' ||
  window.location.href.split('/')[2].includes('dev')
) {
  basePath = 'https://centralapi-dev.kodeia.com/api';
  trackerBasePath = 'https://ai-tracker-dev.kodeia.com/api';
  aiCheckoutTrackingScriptPath =
    'https://static.adup.io/scripts/ai-tracking-development/dist/AITracking.js';
  env = 'DEV';
  shopifyClientId = 'ff78e6ae19ce6293959943d9b38dc939';
} else if (window.location.href.split('/')[2].includes('staging')) {
  basePath = 'https://centralapi-staging.kodeia.com/api';
  trackerBasePath = 'https://ai-tracker-staging.kodeia.com/api';
  aiCheckoutTrackingScriptPath =
    'https://static.adup.io/scripts/ai-tracking-staging/dist/AITracking.js';
  env = 'STAGING';
  shopifyClientId = 'c4747b1d6889e52234a06cf43b303633';
} else {
  basePath = 'https://centralapi.adup.io/api';
  trackerBasePath = 'https://ai-tracker.adup.io/api';
  aiCheckoutTrackingScriptPath =
    'https://static.adup.io/scripts/ai-tracking/dist/AITracking.js';
  env = 'LIVE';
  shopifyClientId = '6bb17edf0e0c092e8020b6908d9d83e9';
}

export const CACHE_BUSTER_EXECUTION_INTERVAL = 30 * 60 * 1000; //* 30 minutes
export const APP_ENV = env;
export const CENTRAL_API_BASE_URL = `${basePath}/${version}/`;
export const TRACKER_API_BASE_URL = `${trackerBasePath}/${version}/`;
export const SHOPIFY_OAUTH_CLIENT_ID = shopifyClientId;

/* ----------AUTHENTICATION ENDPOINT----------- */
export const LOGIN_API = CENTRAL_API_BASE_URL + 'login';
export const LOGOUT_API = CENTRAL_API_BASE_URL + 'logout';
export const PROFILE_DELETE_API = CENTRAL_API_BASE_URL + 'users/delete';

export const REGISTRATION_API = (merchantSlug: string) => {
  return `${CENTRAL_API_BASE_URL}merchants/${merchantSlug}/sellers`;
};

export const USER_INFO_API = CENTRAL_API_BASE_URL + 'user-info';

export const WEBAUTHN_LOGIN_API = CENTRAL_API_BASE_URL + 'webauthn/login';
export const WEBAUTHN_REGISTER_API = CENTRAL_API_BASE_URL + 'webauthn/register';

/* ----------INIT ENDPOINT----------- */
export const INIT_API = CENTRAL_API_BASE_URL + 'seller-init';

/* ----------SHOP ENDPOINT----------- */
export const SHOP_API = CENTRAL_API_BASE_URL + `shops`;
export const CREATE_SHOP_API = (sellerID: string) => {
  return `${CENTRAL_API_BASE_URL}sellers/${sellerID}/shops`;
};

/* ----------SETTINGS ENDPOINT----------- */
export const GENERATE_AUTH_KEY_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/generate-auth-key`;
};
export const USER_SETTINGS_API = CENTRAL_API_BASE_URL + 'users/settings';

/* ----------SHIPPING ENDPOINT----------- */
export const SHIPPING_API = CENTRAL_API_BASE_URL + `shipping`;

/* ----------DISCOUNTS ENDPOINT----------- */
export const DISCOUNTS_TYPES_API = CENTRAL_API_BASE_URL + `discounts/types`;
export const DISCOUNTS_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/discounts`;
};

/* ----------CONVENIENCE FEE ENDPOINT----------- */
export const CONVENIENCE_FEE_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}convenience-fee/${shopSlug}`;
};

/* ----------INTEGRATION ENDPOINT----------- */
export const INTEGRATION_API = CENTRAL_API_BASE_URL + `integrations`;
export const GET_ALL_INTEGRATIONS_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/integrations`;
};

/* ----------PRODUCT ENDPOINT----------- */
export const PRODUCT_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/products`;
};
export const PRODUCT_SYNC_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/sync`;
};

/* ----------ORDERS ENDPOINT----------- */
export const ORDERS_API = CENTRAL_API_BASE_URL + `orders`;

/* ----------CMS ENDPOINT----------- */
export const GET_ALL_CMS_SERVICES_API = CENTRAL_API_BASE_URL + `cms`;
export const CMS_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/cms`;
};

/* ----------PSP ENDPOINT----------- */
export const GET_ALL_PSP_SERVICES_API =
  CENTRAL_API_BASE_URL + `payment-services`;
export const PSP_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/psp`;
};

/* ----------STATISTICS ENDPOINT----------- */
export const STATISTICS_BY_SHOP_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}statistics/shop/${shopSlug}`;
};

export const GRAPHS_API = (integrationKey: string) => {
  return `${TRACKER_API_BASE_URL}company/${integrationKey}/graphs`;
};

/* ----------HELP ENDPOINT----------- */
export const HELP_LINE_API = `${CENTRAL_API_BASE_URL}help-line`;

/* ----------AI_CHECKOUT_TRACKING_LINK----------- */
export const AI_CHECKOUT_TRACKING_LINK = (key: string) => {
  return `${aiCheckoutTrackingScriptPath}?trackingId=${key}`;
};

export const GET_ACCESS_TOKEN_BY_INTEGRATION_KEY_API = (apiKey: string) => {
  return `${CENTRAL_API_BASE_URL}ai-tracker/access-token/${apiKey}`;
};

export const DETECT_CMS_API = `${CENTRAL_API_BASE_URL}ai-tracker/detect-cms`;

export const ONBOARDING_INTEGRATION_ZOHO_API = (sellerID: string) => {
  return `${CENTRAL_API_BASE_URL}onboard-integration/zoho/${sellerID}`;
};

/* ----------AI TRACKER RECOMMENDATIONS ENDPOINT----------- */
export const RECOMMENDATIONS_API = `${TRACKER_API_BASE_URL}recommendations`;
export const CHECK_CONNECTION_API = `${CENTRAL_API_BASE_URL}ai-tracker/check-connectivity`;

/* ----------CUSTOM EVENTS ENDPOINT----------- */
export const AI_CHECKOUT_CUSTOM_EVENTS_API = (apiKey: string) => {
  return `${CENTRAL_API_BASE_URL}integrations/${apiKey}/custom-events`;
};

/* ----------CUSTOM CSS ENDPOINT----------- */
export const AI_CHECKOUT_CUSTOM_CSS_API = (apiKey: string) => {
  return `${CENTRAL_API_BASE_URL}integrations/${apiKey}/custom-css`;
};

/* ----------SUBSCRIPTION ENDPOINT----------- */
export const GET_SUBSCRIPTION_API =
  CENTRAL_API_BASE_URL + `subscriptions/get-available-subscriptions`;
export const MAKE_SUBSCRIPTION_API =
  CENTRAL_API_BASE_URL + `subscriptions/make-subscription`;
export const GET_SUBSCRIPTION_PAYMENT_STATUS_API = (id: string) => {
  return `${CENTRAL_API_BASE_URL}subscriptions/subscription-payment-status/${id}`;
};
export const GET_TARA_SUBSCRIPTION_PACKAGES_API =
  CENTRAL_API_BASE_URL +
  `subscriptions/subscription-types/tara-ai-services/packages`;

/* ----------FORGOT PASSWORD ENDPOINTS----------- */
export const FORGOT_PASSWORD_ENDPOINT =
  CENTRAL_API_BASE_URL + 'forget-password';
export const VERIFY_PASSWORD_OTP_ENDPOINT =
  CENTRAL_API_BASE_URL + 'password/verify-otp';
export const PASSWORD_CHANGE_ENDPOINT =
  CENTRAL_API_BASE_URL + 'password/change';

/* ----------SHOPIFY WRAPPER ENDPOINTS----------- */
export const SHOPIFY_AUTH_URL = (shopDomain: string, redirectURL: string) => {
  return `${shopDomain}/admin/oauth/authorize?client_id=${SHOPIFY_OAUTH_CLIENT_ID}&scope=write_script_tags,write_pixels&redirect_uri=${redirectURL}`;
};
export const SHOPIFY_AUTH_API = `${CENTRAL_API_BASE_URL}shopify/auth`;
export const SHOPIFY_INJECT_SCRIPT_API = `${CENTRAL_API_BASE_URL}shopify/inject-script`;

/* ----------TARA ENDPOINTS----------- */
export const POST_TARA_CHAT_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}chat/${shopSlug}`;
};
export const MCP_SERVERS_API = CENTRAL_API_BASE_URL + 'tara/mcp/index';
export const MCP_CREDENTIALS_API =
  CENTRAL_API_BASE_URL + 'tara/mcp/credentials';

export const GET_TARA_SUBSCRIPTION_STATUS_API =
  CENTRAL_API_BASE_URL + `subscriptions/status`;
