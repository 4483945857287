import TaraHeading from '../taraUI/TaraHeading';
import TaraInput from '../taraUI/TaraInput';
import TaraSearchSuggestions from '../taraUI/TaraSearchSuggestions';

type Props = {
  onCurrentChatUpdate: (chat: any) => void;
  cancelAPICall: () => void;
  loading: 'pending' | 'success' | 'error' | 'null';
};

const TaraNewChat = ({
  onCurrentChatUpdate,
  cancelAPICall,
  loading,
}: Props) => {
  return (
    <div style={{ paddingTop: '4rem' }} className='tara tara-new'>
      <TaraHeading />
      <TaraInput
        updateChat={onCurrentChatUpdate}
        cancelAPI={cancelAPICall}
        loading={loading}
      />
      <TaraSearchSuggestions updateChat={onCurrentChatUpdate} />
    </div>
  );
};

export default TaraNewChat;
