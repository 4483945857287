import { useEffect, useState } from 'react';
import { MessageTypes } from '../../../interfaces/taraInterfaces';
import TaraDummyBarChart from '../../../lib/constants/taraDummyData/TaraDummyBarChart';
import TaraDummyLineChart from '../../../lib/constants/taraDummyData/TaraDummyLineChart';
import MarkdownMessage from './MarkdownMessage';
import '../../../styles/pages/tara/chat/taraChatItemAI.scss';

type Props = {
  message: any;
  messageType: MessageTypes;
  isTyping?: boolean;
};

const TaraChatItemAI = ({ message, messageType, isTyping = false }: Props) => {
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    let timer1: ReturnType<typeof setTimeout>;
    let timer2: ReturnType<typeof setTimeout>;
    let timer3: ReturnType<typeof setTimeout>;
    if (isTyping) {
      timer1 = setTimeout(() => {
        setLoadingText('Communicating with TARA...');
      }, 1000);
      timer2 = setTimeout(() => {
        setLoadingText('Checking connected data sources...');
      }, 3000);
      timer3 = setTimeout(() => {
        setLoadingText('Wrapping up the data');
      }, 5000);
    } else {
      // If not typing, clear the loading text.
      setLoadingText('');
    }
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [isTyping]);

  const renderChart = (message: any) => {
    if (message?.chartType === 'line') {
      return (
        <TaraDummyLineChart
          title={message?.chartTitle}
          chartData={message?.chartData}
        />
      );
    } else if (message?.chartType === 'bar') {
      return (
        <TaraDummyBarChart
          title={message?.chartTitle}
          chartData={message?.chartData}
        />
      );
    }
    return null;
  };

  const renderMessageContent = () => {
    if (isTyping) {
      return (
        <div className='taraChatItemAI__message--loading-container'>
          <div className='taraChatItemAI__message--loading'>
            <div className='taraChatItemAI__message--loading-spinner'>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          {loadingText && (
            <div className='taraChatItemAI__message--loading-text'>
              {loadingText}
            </div>
          )}
        </div>
      );
    }
    if (typeof message === 'string') {
      return <MarkdownMessage content={message} />;
    }
    return message;
  };

  return (
    <div className='taraChatItemAI'>
      {messageType === 'chart' ? (
        <div className='taraChatItemAI__chart'>{renderChart(message)}</div>
      ) : (
        <div
          className='taraChatItemAI__message'
          data-is-typing={isTyping ? 'true' : 'false'}
        >
          {renderMessageContent()}
        </div>
      )}
    </div>
  );
};

export default TaraChatItemAI;
