import { useTranslation } from 'react-i18next';
import { menuDotsVerticalSVG } from '../../../lib/allCustomSVGs';
import '../../../styles/pages/tara/search/taraSearchItem.scss';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import truncate from '../../../lib/helper-functions/truncate';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import TaraHistoryItemDropdown from '../history/TaraHistoryItemDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {
  setAllChatsHistory,
  setCurrentChatID,
  setIsBotTyping,
} from '../../../store/reducers/chatSlice';
import { useNavigate } from 'react-router-dom';

type Props = {
  id: string | number;
  title: string;
  lastUpdated: string;
};

const TaraSearchItem = ({ id, title, lastUpdated }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allChatsHistory = useSelector(
    (state: RootState) => state.chat.allChatsHistory
  );
  const currentChatID = useSelector(
    (state: RootState) => state.chat.currentChatID
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  const onDeleteHistoryItem = (chatId: string | number) => {
    const updatedChatsHistory = allChatsHistory.filter(
      (chat) => chat.chatId !== chatId
    );
    dispatch(setAllChatsHistory(updatedChatsHistory));
    if (currentChatID === chatId) {
      dispatch(setCurrentChatID(null));
      dispatch(setIsBotTyping(false));
    }
  };

  const onHistoryItemClick = (chatId: string | number) => {
    const chatRoute = '/tara/chat/' + chatId;
    navigate(chatRoute);
    if (currentChatID === chatId) return;
    const selectedChat = allChatsHistory.find((chat) => chat.chatId === chatId);
    if (selectedChat) {
      dispatch(setCurrentChatID(selectedChat.chatId));
      dispatch(setIsBotTyping(false));
    }
  };

  return (
    <div className='taraSearchItem'>
      <div
        onClick={() => onHistoryItemClick(id)}
        className='taraSearchItem__top'
      >
        <h3>{truncate(title, 50)}</h3>
        <p>{truncate(title, 50)}</p>
      </div>
      <div className='taraSearchItem__bottom'>
        <span>{`${t('Updated')} ${formatDate(lastUpdated)}`}</span>

        {/* Hidden until backend is supported */}
        {/* <div
          ref={dropdownRef}
          onClick={toggleDropdown}
          className='taraSearchItem__bottom--action'
        >
          <div className='taraSearchItem__bottom--action-svg'>
            <CustomSVGs svg={menuDotsVerticalSVG} />
          </div>
          <AnimatePresence>
            {isDropdownOpen && (
              <TaraHistoryItemDropdown
                onListItemClick={() => onDeleteHistoryItem(id)}
                dropdownId={`dropdown-${id}`}
              />
            )}
          </AnimatePresence>
        </div> */}
      </div>
    </div>
  );
};

export default TaraSearchItem;
